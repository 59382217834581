import React, { memo } from "react";
import axios from "axios";

const instance = axios.create({
	baseURL: "https://osama.in.net/api",
});

export const withActions = (Component, ...props) => {
	return memo(({ children, ...props }) => {
		const getCities = async () => {
			try {
				const res = await instance.get(`/cities`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const updateCity = async (params) => {
			try {
				const res = await instance.post(`cities/${params.cityId}`, params);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const getCategories = async () => {
			try {
				const res = await instance.get(`/categories`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const addCategory = async (params) => {
			try {
				const res = await instance.post(`/categories`, params);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const updateCategory = async (params) => {
			try {
				const res = await instance.post(`/categories/${params.id}`, params);
				console.log("upd", res.data);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const deleteCategory = async (id) => {
			try {
				const res = await instance.delete(`/categories/${id}`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const getHiddenSpotsCategory = async (id) => {
			try {
				const res = await instance.get(`/categories/hidden/${id}`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const addHiddenSpotsCategory = async (params) => {
			try {
				const res = await instance.post(`/categories/hidden`, params);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const updateHiddenSpotsCategory = async (params) => {
			console.log("params", params);
			try {
				const res = await instance.patch(
					`/categories/hidden/${params.id}`,
					params.data
				);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const getProducts = async () => {
			try {
				const res = await instance.get(`/products`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const getProductsByCategory = async (id) => {
			try {
				const res = await instance.get(`/products/category/${id}`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const getHiddenSpotsProduct = async (id) => {
			try {
				const res = await instance.get(`/products/hidden/${id}`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const addProducts = async (params) => {
			try {
				const res = await instance.post(`/products`, params);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const addProductPriceOnSpots = async (params) => {
			try {
				const res = await instance.post(`/products/price`, params);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const addProductHiddenSpots = async (params) => {
			try {
				const res = await instance.post(`/products/hidden`, params);
				console.log("res request", res);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				console.log("errror", error);
				return { message, isError: true };
			}
		};

		const updateHiddenSpotsProduct = async (params) => {
			try {
				const res = await instance.patch(
					`/products/hidden/${params.id}`,
					params.data
				);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const updateProduct = async (params) => {
			try {
				const res = await instance.post(`/products/${params.id}`, params);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const deleteProducts = async (id) => {
			try {
				const res = await instance.delete(`/products/${id}`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const getSettings = async (params) => {
			try {
				const res = await instance.get(`/settings/`);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		const updateAppSettings = async (params) => {
			try {
				const res = await instance.post(`/appsettings/1`, params);
				return res.data;
			} catch (error) {
				const message = error.response.data.message;
				return { message, isError: true };
			}
		};

		return (
			<Component
				getSettings={getSettings}
				getCities={getCities}
				updateCity={updateCity}
				updateAppSettings={updateAppSettings}
				getCategories={getCategories}
				addCategory={addCategory}
				updateCategory={updateCategory}
				deleteCategory={deleteCategory}
				getHiddenSpotsCategory={getHiddenSpotsCategory}
				addHiddenSpotsCategory={addHiddenSpotsCategory}
				updateHiddenSpotsCategory={updateHiddenSpotsCategory}
				getProducts={getProducts}
				getHiddenSpotsProduct={getHiddenSpotsProduct}
				addProducts={addProducts}
				addProductPriceOnSpots={addProductPriceOnSpots}
				addProductHiddenSpots={addProductHiddenSpots}
				updateHiddenSpotsProduct={updateHiddenSpotsProduct}
				getProductsByCategory={getProductsByCategory}
				updateProduct={updateProduct}
				deleteProducts={deleteProducts}
				{...props}
			>
				{children}
			</Component>
		);
	});
};
