import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../../firebase';
import { withActions } from '../../../../api';
import { useSelector, useDispatch } from 'react-redux';
import {  updateCategoriesDispatch } from '../../../../reducers/shops';

import EditCategoryForm from './form';
import useStyles from './styles';

let Categories = (props) => {
    const categoryId = props.match.params.id;
    const classes = useStyles();
    const dispatch = useDispatch();
    const defaultLanguage = useSelector(state => state.shops.defaultLanguage);
    // const category = useSelector(state => state.shops.categories[categoryId]);
    const categories = useSelector(state => state.shops.categories);
    const cities = useSelector(state => state.shops.shops);
    const products = useSelector(state => state.shops.products);
    const language = useSelector(state => state.shops.defaultLanguage);
    const languages = useSelector(state => state.shops.languages);
    const login = useSelector((state) => state.auth.login);

    const [isSelectOpen, setIsSelectOpen] = useState(null);
    const [preloder, setPreloader] = useState(false);
    const [boxState, setBoxState] = useState(null);
    const [sortLanguages, setSortLanguages] = useState(null);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const isCheckBoxList = boxState && Object.keys(boxState).length === selectedTerminals.length;
    const isDebug = JSON.parse(localStorage.getItem('isDebug'))

    const changeSelectClose = () =>  setIsSelectOpen(false);
    const changeSelectOpen = () => setIsSelectOpen(true);

    const category = categories?.find(item => item.id === +categoryId)

    useEffect(() => {
        // if (!languages) getLanguages();

        if (languages && languages.length > 0) {
            sortedLanguages();
        }
    }, [languages]);

    const sortedLanguages = () => {
        languages.sort((a, b) => {
            if (a.key !== defaultLanguage) {
                return 1;
            } else {
                return -1;
            }
        });

        setSortLanguages(languages);
    }

    const handleChange = (event) => {
        const data = [...selectedTerminals];
        const selectedTerminal = data.filter(t => t.visible === "1").length;
        const index = data.findIndex(t => t.id === event.target.name);

        if (selectedTerminal === 1 && data[index].visible === "1") {
            console.log('--- not change ---');
        } else {
            data[index] = { ...data[index], visible: data[index].visible === "1" ? "0" : "1" };
        }

        console.log('selected data',  data)
        setSelectedTerminals(data)
    };

    const getDefaultCities = () => {
        let terminals = [];
        let address = [];
        let defaultSelectedCitiesArr = [];

        cities.map((city) => {
            address.push(city.address);
            for (let key in city.deliveryTerminals) {
                const terminal = city.deliveryTerminals[key];

                terminals.push({ ...terminal, cityId: city.spot_id });
            }
        });

        category && category.spots.map(spot => {
            const terminalId = spot.spot_id;
            for (let key in terminals) {
                const terminal = terminals[key];

                if (Number(terminalId) === Number(terminal.id) && spot.visible === "1") {
                    const { address } = cities.find(city => city.spot_id === terminal.cityId);
                   if (!defaultSelectedCitiesArr.includes(address)) {
                        defaultSelectedCitiesArr.push(address);
                   } 
                }
            }
        });

        return defaultSelectedCitiesArr;
    }

    const getTerminals = () => {
        console.log('--- getTerminals ---');
        let terminals = [];
        // const defaultCities = getDefaultCities();

        // if (cities.length > 1) {
        //     console.log('selectedCities', selectedCities)
 
        //     selectedCities.map(address => {
        //         const { spot_id, deliveryTerminals } = cities.find(city => city.address === address);
          
                
        //         const deliveryTerminalsArr = [...deliveryTerminals];
        //               console.log('deliveryTerminalsArr', deliveryTerminalsArr)
        //         // доставтаь терминалы из городов
        //         console.log('category spots', category.spots)
        //         console.log('category', category)
        //         // const example = category.spots.map(item => item.spot_id)
        //         // console.log('example', example)
        //         deliveryTerminalsArr.map((elem, index) => { 
        //             deliveryTerminalsArr[index] = { 
        //                 ...elem, 
        //                 cityId: spot_id, 
        //                 // visible: '1'
        //                 // visible: example.includes(elem.id) ? '1' : '0',
        //                  visible: category.hiddenOnSpots.includes(elem.id) ? '0' : '1'
        //             };
        //         });

        //         terminals.push(...deliveryTerminalsArr);
        //     });
        //     console.log('terminals', terminals)
        // }

        if (cities.length > 1) {

            const deliveryTerminals = cities.map(item => item.deliveryTerminals[0])
            const hiddenSpots = category.hiddenOnSpots.map(item => +item.spot_id)

            terminals =  deliveryTerminals.map((elem) => ({     
                ...elem, 
                // cityId: spot_id, 
                visible: hiddenSpots.includes(+elem.id) ? '0' : '1'
                }));
        }     

        if (cities.length === 1) {
            const { spot_id, deliveryTerminals } = cities[0];
            const deliveryTerminalsArr = [...deliveryTerminals];
            deliveryTerminals.map((elem, index) => {
                deliveryTerminalsArr[index] = { 
                    ...elem, 
                    cityId: spot_id, 
                    visible: category.spots.find(t => t.spot_id === elem.id).visible,
                };
            });
            terminals.push(...deliveryTerminalsArr);         
        }

        return terminals;
    }

    const changeCity  = (data) => {
        let terminals = [];

        data.map(address => {
            let { spot_id, deliveryTerminals } = cities.find(city => city.address === address);

            deliveryTerminals.map((elem, index) => { 
                deliveryTerminals[index] = { ...elem, cityId: spot_id, visible: "1" };

                selectedTerminals.map(terminal => {
                    if (elem.id === terminal.id) {
                        let isVisible = false;

                        for (let key in terminal) {
                            if (key == 'visible') {
                                isVisible = true;
                            }
                        }

                        deliveryTerminals[index] = { ...terminal, cityId: spot_id, visible: terminal.visible };
                    }
                })
            });

            terminals.push(...deliveryTerminals);
        });

        setSelectedTerminals(terminals);
        setSelectedCities(data);
    }

    useEffect(() => {
        const terminals = getTerminals();
        if (selectedCities.length > 0 && selectedTerminals.length === 0 && category && category.spots) {
            const terminals = getTerminals();
            setSelectedTerminals(terminals);
        }

        if (terminals.length > 0 && selectedTerminals.length === 0) setSelectedTerminals(terminals);
    }, [selectedCities, category]);

    const createNewCategory = (data) => {
        let spots = [];
        let name = {};
        let allTerminals = [];

        cities.map(city => allTerminals.push(...city.deliveryTerminals));
        const icon = category.icon;
        
        for (let index in sortLanguages) {
            const { key } = sortLanguages[index];

            if (data[key]) {
                name[key] = data[key];
            }

        }

        allTerminals.map((terminal, index) => {
            let spot = {
                spot_id: terminal.id,
                visible: "0",
            };

            spots[index] = spot;

            selectedTerminals.map(selectTerminal => {
                if (terminal.id === selectTerminal.id) {
                    spots[index] = {
                        spot_id: terminal.id,
                        visible: selectTerminal.visible
                    };
                }
            });

        });

        const hiddenSpots = spots.filter(item => item.visible === '0').map(item => ({spot_id: +item.spot_id}))
        const visibleSpots = spots.filter(item => item.visible === '1')
       
        const cat = {
            id: category.id,
            order: category.order,
            icon,
            name
        }

        return {cat , hiddenSpots, visibleSpots};
    }

    const updateProducts = (categoryId) => {
        const result = { ...products };

        for (let key in products) {
            let product = products[key];
            let newProduct = { ...product };
            let spots = [];

            if (categoryId === product.menuCategoryId) {
                for (let c in category.spots) {
                    if (category.spots && product.spots && category.spots.length !== product.spots.length) {
                        // console.log('-- 1 --', product);
                        let productSpots = product.spots.filter(item => !!item);
                        const categorySpot = category.spots[c]; 
                        const productSpot = productSpots[c]; 

                        // console.log('productSpot', productSpot);
                        
                        if (productSpot) {
                            spots.push({ 
                                ...productSpot, 
                                visible: productSpot.price === "0" && productSpot.visible === "0" || productSpot.price !== "0" && productSpot.visible === "0" ? "0" : categorySpot.visible
                            });
                        } else {
                            spots.push({ price: "0", spot_id: categorySpot.spot_id, visible: "0" });
                        }
                    }

                    if (!product || !product.spots || category.spots.length === product.spots.length) {
                        // console.log('-- 2 --', product);
                        let productSpots = product.spots.filter(item => !!item);

                        for (let p in productSpots) {
                            const categorySpot = category.spots[c]; 
                            const productSpot = productSpots[p];

                            if (categorySpot.spot_id === productSpot.spot_id) {
                                // console.log('categoryVisible, productVisible', categorySpot.visible, productSpot.visible);
                                const visible = productSpot.visible === "1" && productSpot.price > 0 ? "1" : "0";

                                // const visible = productSpot.price === "0" && productSpot.visible === "0" || productSpot.price !== "0" && productSpot.visible === "0" ? "0" : categorySpot.visible;
                                // const visible = productSpot.price === "0" && productSpot.visible === "0" ? "0" : productSpot.price > "0" && productSpot.visible === "1" ? "1" : categorySpot.visible;
                                spots.push({
                                     ...categorySpot, 
                                     price: productSpot.price, 
                                     visible
                                });
                            }
                        }
                    }
                }
                newProduct.spots = spots;
                result[key] = newProduct;
                // console.log('spots', spots);
            }
        }

        return result;
    }

    const onSubmit = async (data) => {
        console.log('--- Submit data ---', data);

        const categoryData = createNewCategory(data);

        const category = categoryData.cat;
        const hiddenOnSpots = categoryData.hiddenSpots
        const spots = categoryData.visibleSpots
        
        setPreloader(true)
        const res = await props.updateCategory(category)
      
        if(!!res) {
            const updatedCategories = categories.map(item => item.id === category.id ? {...category, hiddenOnSpots, spots } : item)
            await props.updateHiddenSpotsCategory({id: category.id, data: hiddenOnSpots})
            updateCategoriesDispatch(dispatch, updatedCategories);

            setPreloader(false);
            props.history.push('/page/menu/products');
        } else {
            alert("Что-то пошло не так ...");
            setPreloader(false);
        }
        

        // setPreloader(true);
        // props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/categories/${categoryId}`).update(category)
        //     .then(res => {
        //         let data = { id: categoryId, category };

        //         const productsData = updateProducts(categoryId, category);

        //         props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/products`).update(productsData)
        //             .then(res => {
        //                 let categoriesOrderData = [];

        //                 for (let key in categories) {
        //                     categoriesOrderData.push(key);
        //                 }
            
        //                 props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/Category/categoriesOrder`).update(categoriesOrderData)
        //                     .then(res => {
        //                         updateCategoriesOrder(categoriesOrderData);
        //                         editCategory(dispatch, data);
        //                         updateProductsDispatch(dispatch, productsData);
        //                         setPreloader(false);
        //                         props.history.push('/page/menu/products/');
        //                     })
        //                     .catch(error => {
        //                         console.error(error);
        //                     });
        //             })
        //             .catch(error => {
        //                 console.error(error);
        //             });
        //     })
        //     .catch(error => {
        //         console.error(error);
        //         setPreloader(false);
        //     });
        //     if (login === "osama-sushi") {
        //     props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/updates`).update({ lastUpdateInMenu: Date.now() });
        //     }
    }

    return(
        <div className={classes.root}>
            <div className={classes.modal}>
                <EditCategoryForm
                    load={preloder} 
                    language={language}
                    languages={sortLanguages ? sortLanguages : []}
                    category={category}
                    cities={cities}
                    isCheckBoxList={isCheckBoxList}
                    selectedTerminals={selectedTerminals}
                    boxState={boxState}
                    onSubmit={onSubmit}
                    handleChange={handleChange}
                    changeSelectClose={changeSelectClose}
                    changeSelectOpen={changeSelectOpen}
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
                    changeCity={changeCity}
                />
            </div>
        </div>
    )
}

export default withActions(withFirebase(withRouter(Categories)));